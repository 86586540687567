.header{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 2rem; 
    padding-top: 1.5rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.header-menu>li:hover{
cursor: pointer;
color: var(--orange);

}